(function($){
    'use strict';

    if (typeof GF == 'undefined'){
        return;
    }

    GF.framework.GridFilters.prototype.renderMenu = function () {
        var that = this,
            menu = $('<div></div>', { 'class': 'btn-group' }).css({
                        padding: '10px 0 0'
                    });

        if (this.config.showControlButtons) {
            menu.append($('<button></button>', {
                    type: 'submit',
                    'class': GF.render.c.button('lg', 'primary')
                }).on('click', function () {
                    that.ApplyFilters();
                }).append('<span>Apply</span>'));

            var undo = $('<button></button>', {
                type: 'button',
                'class': GF.render.c.button('lg', 'link') + ' undo-button'
            }).on('click', function () {
                that.UndoLatestChanges();
            }).append('<i class="icon icon-backward"></i><span>Undo</span>');

            if (!this.HasChanges()) {
                undo.attr('disabled', 'disabled');
            }

            menu.append(undo);

            // menu.append($('<button></button>', {
            //         type: 'button',
            //         'class': GF.render.c.button('lg', 'link')
            //     }).on('click', function () {
            //         that.container.trigger('closeButtonClick', [ that.HideWidget() ]);
            //     }).append('<i class="icon icon-cancel-circle"></i><span>Close</span>'));
        }

        menu.append(
            $('<button></button>', {
                type: 'button',
                'class': GF.render.c.button('lg', 'link')
            }).on('click', function () {
                that.ResetFilter();
            }).append('<i class="icon icon-cancel-circle"></i><span>Clear</span>')
        );

        if (this.config.allowPresetSave) {
            menu.append(
                $('<button></button>', {
                    type: 'button',
                    'class': GF.render.c.button('lg', 'link') + ' btn-success'
                }).on('click', function () {
                    that.ShowSaveFilterDialog();
                }).append('<i class="icon icon-check-circle"></i><span>Save</span>')
            );
        }

        if (this.config.allowPresetLoad) {
            menu.append(
                $('<button></button>', {
                    type: 'button',
                    'class': GF.render.c.button('lg', 'link') + ' btn-default'
                }).on('click', $.proxy(that.ShowLoadFilterDialog, this))
                .append('<i class="icon icon-download-1"></i><span>Load</span>')
            );
        }

        if (this.config.shareUrl) {
            var $shareButton = $('<button type="button" data-share-type="popover">Share</button>')
                    .addClass(GF.render.c.button(null, 'success'));

            this.addSharePopover($shareButton);

            menu.append($shareButton);
        }

        if (this.config.previewUrl) {
            var $previewButton = $('<button type="button">Preview</button>')
                .addClass(GF.render.c.button(null, 'success'))
                .on('click', function(){
                    var $previewUrl = that.config.previewUrl(that.GetCurrentFilter());
                    window.open($previewUrl);
                });

            menu.append($previewButton);
        }

        return menu;
    };

})(jQuery);
