let EventTracker = (function($){
    'use strict';

    $(document).on('ready', function(){
        if (PIM.disable_user_tracking){
            return;
        }

        EventTracker.processEvents();
    });

    return {

        serializeClean: function(form){
            return $(form).find(':input').filter(function(){
                return this.type != 'password' && this.name != '_csrf';
            }).serializeArray().reduce(function(a, x) {
                if (!(x.name in a)) {
                    a[x.name] = [];
                }
                a[x.name].push(x.value);
                return a;
            }, {});
        },

        trackEvent: function (category, action, params, in_process) {
            if (PIM.disable_user_tracking){
                return;
            }
            params = params || {};
            this.trackUserEvent(category, action, params, in_process);
        },

        trackUserEvent: function (category, action, params, in_process) {
            if (PIM.disable_user_tracking){
                return;
            }
            params = params || {};

            if (PIM.page_tracking_url){
                const url = params.url || window.location.href;
                delete params.url;
                const frontend_date = params.frontend_date || new Date();
                delete params.frontend_date;
                $.ajax({
                    method: 'post',
                    url: PIM.page_tracking_url,
                    data: {
                        '_csrf': PIM.csrf_token,
                        'entity': category,
                        'action': action,
                        'url': url,
                        'frontend_date': JSON.stringify(frontend_date),
                        'params': JSON.stringify(params),
                    },
                }).fail(function(req, text, err){
                    if (!params.attempt_count) {
                        params.attempt_count = 0;
                    }
                    params.attempt_count++;
                    if (params.attempt_count > 5) {
                        return;
                    }
                    params.url = url;
                    EventTracker.scheduleEvent(category, action, params);
                });
            }

        },

        getEvents: function () {
            let events;
            try {
                if (PIM.storage && PIM.storage.localStorage){
                    events = PIM.storage.localStorage.get('tracker-events');
                }
            } catch(err){
                window.trackJs && trackJs.console.error(err);
            }
            return events ? events : [];
        },

        getNextEvent: function () {
            let val;
            this.lock = true;
            try{
                const events = this.getEvents();
                val = events.pop();
                this.setEvents(events);
            } catch(err){
                window.trackJs && trackJs.console.error(err);
            }
            this.lock = false;
            return val;
        },

        setEvents: function (events) {
            if (PIM.storage && PIM.storage.localStorage){
                try {
                    PIM.storage.localStorage.set('tracker-events', events);
                } catch(err) {
                    window.trackJs && trackJs.console.error(err);
                }
            }
        },

        processEvents: function () {
            if (PIM.disable_user_tracking){
                return;
            }
            if (this.lock) {
                setTimeout(this.processEvents(), 100);
                return;
            }
            let item = this.getNextEvent();
            while(item) {
                this.trackEvent(item[0], item[1], item[2], true);
                item = this.getNextEvent();
            }
        },

        scheduleEvent: function () {
            if (PIM.disable_user_tracking){
                return;
            }
            if (this.lock) {
                setTimeout(this.scheduleEvent(arguments), 100);
                return;
            }
            if (!('frontend_date' in arguments[2])) {
                arguments[2].frontend_date = new Date();
            }
            const events = this.getEvents();
            events.push(Array.prototype.slice.call(arguments));
            this.setEvents(events);
        }
    };
})(jQuery);


;(function($){
    'use strict';

    $(document).on('submit', 'form', function(ev) {
        if (PIM.disable_user_tracking){
            return;
        }

        const form = $(this),
            data = {url: form.attr('action') || location.href},
            tracking_class = form.data('tracking-class'),
            tracking_id = form.data('tracking-id'),
            tracking_meta = form.data('tracking-meta');

        let tracking_method = form.data('tracking-method');

        data.form = EventTracker.serializeClean(form);

        if (tracking_meta){
            $.extend(true, data, tracking_meta);
        }
        if (tracking_id){
            data.item_id = tracking_id;
        }
        data.frontend_date = new Date();
        if (tracking_method) {
            const re = tracking_method.match(/^(.*)-ajax$/);
            if (re) {
                tracking_method = re[1];
                data.ajax = true;
            }

            EventTracker.scheduleEvent(tracking_class, tracking_method, data);
        }
    });
})(jQuery);
