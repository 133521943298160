window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            window.oRequestAnimationFrame      ||
            window.msRequestAnimationFrame     ||
            function(/* function */ callback, /* DOMElement */ element){
                window.setTimeout(callback, 1000 / 60);
            };
})();

jQuery(function($){
    'use strict';

    var $body = $(document.body),
        $window = $(window);

    $('.navbar-side-links').each(function(){
        var $nav = $(this),
            $tabs = $nav.find('a[data-toggle="tab"]'),
            $navLevel2 = $nav.find('.nav-level2'),
            navLevel2Show = false;

        $tabs.on('show.bs.tab', function (e) {
            $tabs.parent().removeClass('active');

            if ($window.width() < 992) {
                $('.navbar-side-links').collapse('hide');
            }
        });

        $navLevel2
            .on('show.bs.collapse', function (e) {
                navLevel2Show = true;

                var $el = $(this),
                    windHeight = $window.height();

                if ($window.width() > 992) {
                    (function animloop(){
                        setTimeout(function(){
                            var dif = $el.outerHeight() + $el.offset().top - windHeight;

                            if (dif > 0) {
                                $nav.scrollTop($nav.scrollTop() + dif);
                            }
                        });

                        if (navLevel2Show) {
                            requestAnimFrame(animloop);
                        }
                    })();
                }
            })
            .on('shown.bs.collapse', function (e) {
                navLevel2Show = false;
            });
    });

    (function(){
        var timeout;
        function updateHeight(){
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(function(){
                $('.sticky-header').each(function(){
                    var $header = $(this);

                    if ($header.offset().top - $window.scrollTop() === 50 && $window.scrollTop() > 0) {
                        if (!$header.hasClass('sticky-fixed')) {
                            $header.addClass('sticky-fixed')
                        }
                    } else {
                        if ($header.hasClass('sticky-fixed')) {
                            $header.removeClass('sticky-fixed')
                        }
                    }
                });
            }, 100);
        }

        updateHeight();
        $window.on('resize.stickyHeader scroll.stickyHeader', updateHeight);
        if(window.MutationObserver){
            new MutationObserver(updateHeight).observe(document.documentElement, {childList: true, subtree: true, attributes: true});
        }

        var $stickyHeaderScroll;

        $(document)
            .on('click', '.sticky-header .nav-tabs a', function(){
                $window.scrollTop(0);
            })
    })();

    $(document)
        .on('click.bs.dropdown.data-api', '.btn-group > [data-toggle="dropdown"]', function(){
            var $btn = $(this),
                $dropdownMenu = $btn.next('.dropdown-menu');

            if (!$dropdownMenu.length) return;

            var $window = $(window);

            $dropdownMenu.removeClass('dropdown-menu-bottom');

            if(!$btn.data('force-bottom') && $window.height() < $dropdownMenu.offset().top + $dropdownMenu.outerHeight() - $window.scrollTop()){
                $dropdownMenu.addClass('dropdown-menu-bottom');
            } else {
                $dropdownMenu.removeClass('dropdown-menu-bottom');
            }
        })
});

jQuery(function($){
    var initTimeout,
        checkTimeout,
        $window = $(window);

    function initGrids(){
        if (initTimeout) {
            clearTimeout(initTimeout);
        }

        initTimeout = setTimeout(function(){
            $('.table-responsive').not('.no-responsive-table-scroll').each(function(){
                var $tableResponsive = $(this),
                    inited = $tableResponsive.data('tableGridScroll');

                if (inited) return;

                $tableResponsive.data('tableGridScroll', true);

                var $nextPrev = $('<div class="table-responsive-nextprev noselect hidden"><div class="icon icon-arrow-1-left" data-dir="-1"></div><div class="icon icon-arrow-1-right" data-dir="1"></div></div>'),
                    interval;

                $tableResponsive.append($nextPrev);

                $nextPrev.find('.icon')
                    .on('mousedown touchstart', function(){
                        var dir = $(this).data('dir');

                        interval = setInterval(function(){
                            $tableResponsive.scrollLeft($tableResponsive.scrollLeft() + (dir*2));
                        })
                    });
                $(document).on('mouseup touchend', function(){
                    if (interval) {
                        clearInterval(interval);
                        interval = null;
                    }
                });
            });

            checkGrids();
        }, 100);
    }

    function checkGrids(){
        if (checkTimeout) {
            clearTimeout(checkTimeout);
        }

        checkTimeout = setTimeout(function(){
            $('.table-responsive').not('.no-responsive-table-scroll').each(function(){
                var $tableResponsive = $(this);

                if ($tableResponsive.get(0).scrollWidth > $tableResponsive.width()
                    && $tableResponsive.offset().top + $tableResponsive.outerHeight() > $window.scrollTop() + $window.height()
                    && $tableResponsive.offset().top < $window.scrollTop() + $window.height() - 100) {

                        $tableResponsive.find('.table-responsive-nextprev').removeClass('hidden');
                } else {
                    $tableResponsive.find('.table-responsive-nextprev').addClass('hidden');
                }
            });
        }, 10);
    }

    initGrids();
    $(window).on('resize scroll', checkGrids);
    if(window.MutationObserver){
        new MutationObserver(initGrids).observe(document.documentElement, {childList: true, subtree: true, attributes: true});
    }
});

jQuery(function($){
    'use strict';

    if (/iPhone|iPod|Android/i.test(navigator.userAgent)) {
        var height;
        var interval;

        $(document.body)
            .on('dp.show', function(e) {
                var $widget = $('.bootstrap-datetimepicker-widget');

                $widget.css('top', 0);

                if (/iPhone|iPod/i.test(navigator.userAgent)) {
                    setTimeout(function() {
                        $widget.css('top', $(window).scrollTop() - $widget.offset().top + 15);
                    }, 150);
                }

                bodyScrollLock.disableBodyScroll($widget[0]);
            })
            .on('dp.hide', function(e) {
                bodyScrollLock.clearAllBodyScrollLocks();
            })
            .on('show.bs.select', function(e) {
                var selectpicker = $(e.target).data('selectpicker');

                if(!selectpicker.$menu.find('.close-bootstrap-select-menu').length) {
                    selectpicker.$menu.append('<div class="close-bootstrap-select-menu">Close</div>');
                }

                function updateHeight() {
                    var _height = window.innerHeight;

                    
                    if(height !== _height) {
                        height = _height;
                        selectpicker.$menuInner.css('maxHeight', height - 110);
                    }
                }

                updateHeight();

                setTimeout(function() {
                    if($('.bootstrap-select.open').is(selectpicker.$newElement)){
                        bodyScrollLock.disableBodyScroll(selectpicker.$menuInner[0]);
                        interval = setInterval(updateHeight, 50);
                    }
                }, 300);
            })
            .on('hidden.bs.select select.bs.select', function() {
                height = null;
                clearInterval(interval);
                bodyScrollLock.clearAllBodyScrollLocks();
            });
    }
});
