(function($){
    'use strict';

    $(document).on('click', '*[data-toggle="download-image"]', function(ev){

        var self = $(this),
            url = self.data('remote'),
            item_id = self.data('tracking-id');

        if (typeof EventTracker != 'undefined'){
            EventTracker.trackEvent('file', 'download', {'url': url, 'item_id': item_id});
        }

    });

    $(document).on('change', '*[data-toggle="upload-image"]', function(ev){

        var self = $(this),
            data = {
                url: self.data('remote'),
            };
        var files = $(this).find('[type="file"]').prop("files");
        data.files = $.map(files, function(val) { return val.name; });

        if (self.data('tracking-class') && typeof EventTracker != 'undefined'){
            EventTracker.trackEvent(self.data('tracking-class'), self.data('tracking-method'), data);
        }

    });

})(jQuery);
