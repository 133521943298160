if (typeof 'GF' != 'undefined'){
    GF.defaults.quickFilter.showPlaceholder = true;
    GF.defaults.quickFilter.applyButtonIcon = 'search';
    GF.defaults.datepickerSettings.widgetParent = '#page-wrapper';
    GF.defaults.selectpickerOptions.container = 'body';
}

if (jQuery.fn.editabletypes){
    jQuery.fn.editabletypes.textarea.defaults.inputclass = 'form-control';
}

if (typeof HandlebarsIntl != 'undefined'){
    HandlebarsIntl.registerWith(Handlebars);
}

if (jQuery.fn.rating && jQuery.fn.rating.Constructor){
    jQuery.fn.rating.Constructor.DEFAULTS.iconLib = 'fa';
    jQuery.fn.rating.Constructor.DEFAULTS.activeIcon = 'fa-star';
    jQuery.fn.rating.Constructor.DEFAULTS.inactiveIcon = 'fa-star-o';
    jQuery.fn.rating.Constructor.DEFAULTS.clearableIcon = 'fa-remove';
}

jQuery(function($){
    var sizeLimit = parseInt($('meta[name="pim:upload:size-limit"]').prop('content'), 10) || 0,
        sizeLimitMb = '' + Math.round(sizeLimit / 1024 / 1024) + 'Mb';
    PIM.FileUploadWidget.defaultOptions.sizeLimit = sizeLimit;
    PIM.FileUploadWidget.defaultOptions.messages.errorSize = 'File size exceeds ' + sizeLimitMb + ' limit per file. Please select smaller files.';
    PIM.FileUploadWidget.defaultOptions.fileBatchSize = parseInt($('meta[name="pim:upload:batch-size"]').prop('content'), 10) || 100;
    PIM.FileUploadWidget.defaultOptions.totalCountLimit = parseInt($('meta[name="pim:upload:total-count-limit"]').prop('content'), 10) || 600;
});

PIM.IntlFormat = {
    'locales': 'en-GB',
    'formats': {
        'date': {
            'human': {
                'day': 'numeric',
                'month': 'short',
                'year': 'numeric'
            },
            'human hhmm': {
                'day': 'numeric',
                'month': 'short',
                'year': 'numeric',
                'hour': 'numeric',
                'minute': 'numeric',
                'timeZone': 'Europe/London',
                'hour12': false
            },
        },
        'time': {
            'hhmm': {
                'hour': 'numeric',
                'minute': 'numeric',
                'hour12': false
            },
            'hhmm_frontend': {
                'hour': 'numeric',
                'minute': 'numeric',
                'timeZone': 'Europe/London',
                'hour12': false,
                'timeZoneName': 'short'
            }
        }
    }
};


PIM.saveLastOpenTab = function(container, id, callback){
    container = $(container);
    container.find('a[data-toggle="tab"]').on('shown.bs.tab', function(){
        try {
            Storages.localStorage.set(id, $(this).attr('href'));
        }
        catch(err) {
            window.trackJs && trackJs.console.error(err);
        }
    });

    var lastTab = Storages.localStorage.get(id),
        el;
    if (location.hash){
        lastTab = location.hash;
        location.hash = '';
    }
    if (lastTab){
        el = container.find('a[href="' + lastTab + '"]');
    }
    if (callback){
        callback(container, el, id, lastTab);
    } else {
        if (el && el.length){
            el.tab('show');
        } else {
            container.find('a[data-toggle="tab"]:first').tab('show');
        }
    }

    $(window).on('hashchange', function(){
        var lastTab = location.hash,
            el = $(container).find('a[href="' + lastTab + '"]');

        if (container.siblings('.tab-content').find(lastTab).is('.active, .loading')){
            return;
        }

        if (callback){
            callback(container, el, id, lastTab);
        } else {
            if (el && el.length){
                el.tab('show');
            }
        }
    });

};


(function($){
    'use strict';

    var updateToolbar_orig = PIM.GridWidget.prototype.updateToolbar;
    PIM.GridWidget.prototype.updateToolbar = function(){
        if (updateToolbar_orig.apply(this, arguments)){
            this.gridElement().find('[data-toggle="tooltip"]').tooltip({'container': 'body'});
            return true;
        }
        return false;
    };

    var loadPartialBefore_orig = PIM.GridWidget.prototype._loadPartialBefore;
    PIM.GridWidget.prototype._loadPartialBefore = function(ev, hist_url, additional, type){
        loadPartialBefore_orig.apply(this, arguments);
        this.gridElement().data('trigger-event-tracker', false);

        if (typeof EventTracker != 'undefined'){
            EventTracker.trackEvent(this.filters.config.baseEntityLabel || this.filters.config.baseEntity, 'filter', {
                'url': hist_url,
                'additional': ($.type(additional) === 'string') ? additional : JSON.stringify(additional),
                'type': type
            });
        }
    };

    var applyFilters_orig = PIM.GridWidget.prototype.applyFilters;
    PIM.GridWidget.prototype.applyFilters = function(){
        this.gridElement().data('trigger-event-tracker', true);
        return applyFilters_orig.apply(this, arguments);
    };

})(jQuery);


(function($){
    'use strict';

    PIM.StaticGridWidget_Tracking = function(options){
        options = $.extend(true, PIM.StaticGridWidget_Tracking.defaultOptions, options);
        PIM.StaticGridWidget.call(this, options);
    };

    PIM.StaticGridWidget_Tracking.defaultOptions = {
        'selectionSelector': '[type=checkbox][name=track_id]',
    };

    PIM.StaticGridWidget_Tracking.prototype = Object.create(PIM.StaticGridWidget.prototype);
    PIM.StaticGridWidget_Tracking.prototype.constructor = PIM.StaticGridWidget_Tracking;

    PIM.StaticGridWidget_Tracking.prototype._ajaxUpdate = function(){
        var grid = this.gridElement(),
            selector = this.options.selectionSelector;
        PIM.StaticGridWidget.prototype._ajaxUpdate.apply(this, arguments);
        $.each(this._selection || [], function(idx, value){
            grid
                .find(selector)
                .filter('[value="' + value + '"]')
                .prop('checked', true);
        });
        this._selection = [];

        if (this._destination){
            this.gridElement()
                .find('form.product-tracking-move [name="destination"][value="'+this._destination+'"]')
                .closest('.btn')
                .button('toggle');
            this._destination = null;
        }
    };

    PIM.StaticGridWidget_Tracking.prototype._prepareReloadParams = function(options, extend, key){
        var data = {'params': {}};
        $.each(this.container().find(this.options.toolbarFormSelector).serializeArray(), function(i, field){
            data.params[field.name] = field.value;
        });
        extend = $.extend(data, extend);
        return PIM.StaticGridWidget.prototype._prepareReloadParams.call(this, options, extend, key);
    };

    PIM.StaticGridWidget_Tracking.prototype.reload = function(options){
        this._selection = this.gridElement().find(this.options.selectionSelector).filter(':checked').map(function(){
            return $(this).prop('value');
        }).get();
        this._destination = this.gridElement().find('form.product-tracking-move [name="destination"]:checked').val();
        return PIM.StaticGridWidget.prototype.reload.apply(this, arguments);
    };

})(jQuery);

+function($){
    'use strict';

    $(document).ajaxComplete(function(event, request, settings) {
        if (request.status === 403) {
            BootstrapDialog.show({
                'type': BootstrapDialog.TYPE_ERROR,
                'title': 'WARNING',
                'message': 'Token has expired. Please relogin.',
                'buttons': [
                    {
                        'label': 'OK',
                        'cssClass': 'btn-primary',
                        'action': function(dialog){
                            window.location.reload();
                        }
                    }
                ]
            });
        }
    });

}(jQuery);
