+function($){
    'use strict';

    PIM.groupSelector = function (selector){
        selector = $(selector);
    
         // Set corresponding value and text to "--Select All--" options
        selector.children("optgroup").each(function(i, element){
            var mass_option = $(this).children("option[value = 'mass_option_select']")[0],
                total_count = $(this).children("option[value != 'mass_option_select']").length,
                selected_count = $(this).children("option:selected").length;
    
            if (mass_option){
                if (total_count == selected_count){
                    mass_option.value = 'unselect-all';
                    mass_option.text = '-- Unselect All --';
                }else {
                    mass_option.value = 'select-all';
                    mass_option.text = '-- Select All --';
                }
            }
        });
        
        // Select and Deselect options in optgroup by clicking on "--Select all--" option
        selector.change(function(ev){
            var element = $(this),
                selected_options = this.selectedOptions,
                current_selection = element.val() || [],
                new_selection = [];
    
            for (var i=0; i < selected_options.length; i++){
                var option = selected_options[i];
                if (option.value == "select-all"){
                    var groupOptions = option.parentNode.children;
    
                    for (var k=0; k < groupOptions.length; k++){
                        var value = groupOptions[k].value;
                        new_selection.push(value);
                    }
    
                    current_selection = current_selection.concat(new_selection);
                    current_selection = current_selection.filter(function(element){return element != "select-all"});
                    option.value = 'unselect-all';
                    option.text = "-- Unselect All --";
                    element.val(current_selection);
                    if ($.fn.selectpicker){
                        element.selectpicker('refresh');
                    }
                    break;
    
                } else if (option.value == "unselect-all"){
                    var groupOptions = option.parentNode.children;
    
                     for (var k=0; k < groupOptions.length; k++){
                        var value = groupOptions[k].value;
                        new_selection.push(value);
                    }
    
                    current_selection = $(current_selection).not(new_selection).get();
                    option.value = 'select-all';
                    option.text = "-- Select All --";
                    element.val(current_selection);
                    if ($.fn.selectpicker){
                        element.selectpicker('refresh');
                    }
                    break;
                }
            }
        });
    
    };

}(jQuery);
